/* Loader.module.css */
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .loaderSpinner {
    border: 4px solid rgba(255, 255, 255, 0.25); /* Outer border */
    border-top: 4px solid #22c55e; /* Spinner color */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  .animateFadeIn {
    animation: fadeIn 0.5s ease-in-out;
  }
  